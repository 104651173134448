import React from "react";
/* componentes de material ui */
import { Box, Typography } from "@mui/material";
/* material ui Icon */
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const CardOne = (props) => {
  const { extranjero, nacional, transito } = props;

  return (
    <>
      {/* priera card o box */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          // maxWidth: "380px",
          height: "160px",
          border: "1.5px solid #F6F6F6",
          borderRadius: "16px",
          p: 2,
        }}
      >
        <Box sx={{ width: "70%" }}>
          <Box sx={{ display: "flex" }}>
            <FiberManualRecordIcon fontSize="small" sx={{ color: "#CE1336" }} />
            &nbsp;
            <Typography sx={{ fontSize: "0.9em" }}>
              Inversiones en Perú
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <FiberManualRecordIcon fontSize="small" sx={{ color: "#8A001A" }} />
            &nbsp;
            <Typography sx={{ fontSize: "0.9em" }}>
              Inversiones Extranjeras
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <FiberManualRecordIcon fontSize="small" sx={{ color: "#8E9B9E" }} />
            &nbsp;
            <Typography sx={{ fontSize: "0.9em" }}>
              Operaciones de tránsito
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            width: "30%",
            height: "100%",
            color: "white",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50%",
              width: "100%",
              maxWidth: "90px",
              backgroundColor: "#CE1336",
              borderRadius: "16px 16px 0 0 ",
            }}
          >
            <Typography sx={{ fontSize: "0.8em" }}>{nacional}</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "35%",
              width: "100%",
              maxWidth: "90px",
              backgroundColor: "#8A001A",
            }}
          >
            <Typography sx={{ fontSize: "0.8em" }}>{extranjero}</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "15%",
              width: "100%",
              maxWidth: "90px",
              backgroundColor: "#8E9B9E",
              borderRadius: "0 0 16px 16px",
            }}
          >
            <Typography sx={{ fontSize: "0.8em" }}>{transito}</Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default CardOne;
