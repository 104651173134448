import React, { useState, useEffect } from "react";

/* componentes de material ui */
import {
  Box,
  Card,
  Typography,
  Grid,
  Checkbox,
  useMediaQuery,
  useTheme,
  Chip,
} from "@mui/material";

/* material ui carousel */
import Carousel from "react-material-ui-carousel";

/* Iconos material ui */
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";

/* iconos de los gráficos */
import GraficCeroActiveIcon from "../../../assets/IconBucket/Icon/GraficOneActiveIcon";
import GraficOneActiveIcon from "../../../assets/IconBucket/Icon/GraficOneActiveIcon";
import GraficTwoActiveIcon from "../../../assets/IconBucket/Icon/GraficTwoActiveIcon";
import GraficThreeActiveIcon from "../../../assets/IconBucket/Icon/GraficThreeActiveIcon";

import GraficCeroDisableIcon from "../../../assets/IconBucket/Icon/GraficOneDisableIcon";
import GraficOneDisableIcon from "../../../assets/IconBucket/Icon/GraficOneDisableIcon";
import GraficTwoDisableIcon from "../../../assets/IconBucket/Icon/GraficTwoDisableIcon";
import GraficThreeDisableIcon from "../../../assets/IconBucket/Icon/GraficThreeDisableIcon";

/* estilos */
export const styles = {
  cardStyle: {
    width: "100%",
    maxWidth: "250px",
    fontSize: "10px",
    border: "1.5px solid #ACAFB3",
    borderRadius: "16px",
    cursor: "pointer",
    userSelect: "none",
    boxShadow: "0px 8px 24px rgba(27, 29, 39, 0.17)",
  },
  cardStyleChanges: {
    width: "100%",
    maxWidth: "250px",
    fontSize: "10px",
    borderRadius: "16px",
    border: "1.5px solid #CE1336",
    cursor: "pointer",
    userSelect: "none",
    boxShadow: "0px 8px 24px rgba(27, 29, 39, 0.17)",
  },
  cardAlReadySelectedStyle: {
    width: "100%",
    fontSize: "10px",
    borderRadius: "16px",
    border: "1.5px solid #EDEDED",
    cursor: "pointer",
    userSelect: "none",
    maxWidth: "250px",
    boxShadow: "0px 8px 24px rgba(27, 29, 39, 0.17)",
  },
  cardContentStyle: {
    height: "220px",
    p: 2,
  },
  boxContainerTitle: {
    borderRadius: "8px",
    bgcolor: "#2F6D95",
    width: "90%",
  },
  boxContainerTitleChanges: {
    borderRadius: "8px",
    bgcolor: "#B7E3FF",
    width: "85%",
  },
  boxContainerAlReadySelectedStyle: {
    borderRadius: "8px",
    bgcolor: "#B7E3FF",
    width: "85%",
  },
  boxContainerTitleChangesXL: {
    borderRadius: "8px",
    bgcolor: "#B7E3FF",
    width: "80%",
  },
  titleDesktopStyle: {
    fontSize: "1.5em",
    pt: "6px",
    fontWeight: "bold",
    color: "white",
  },
  titleResponsiveStyle: {
    fontSize: "1.2em",
    pt: "6px",
    fontWeight: "bold",
    color: "white",
  },
  gridCheckboxStyle: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
  },
  gridRiesgoStyle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  gridRentabilidadStyle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    p: 1,
    bgcolor: "#F6F6F6",
    borderRadius: "8px",
  },
};

const BasicCard = ({
  info,
  defaultSelect,
  buttonType,
  OptionSelect,
  fondoActual,
  step,
  fondoOrigen,
  setFondoDestino,
  setChangeSwipeableState,
  etario,
}) => {
  /* Hooks para los cambios al ser seleccionados */
  const [typeButton, setTypeButton] = useState(false);
  const [opcionSeleccionada, setOpcionSeleccionada] = useState(defaultSelect);
  /* material ui */
  const theme = useTheme();
  const matchOne = useMediaQuery("(min-width:400px)");
  const matchTwo = useMediaQuery("(min-width:430px)");
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const screenXL = useMediaQuery(theme.breakpoints.down("xl"));

  /* Hook verifica la data */
  useEffect(() => {
    if (buttonType === "eligeFondo") setTypeButton(true);
  }, [buttonType, info]);

  useEffect(() => {
    if (step === 0) {
      setOpcionSeleccionada(defaultSelect);
    }
  }, [defaultSelect, step]);

  /* manejo del evento onClick */
  const handleClick = (opcion) => {
    setOpcionSeleccionada(opcion);
    setFondoDestino(opcion);
    setChangeSwipeableState(false);
  };

  const descriptionFund = etario ? "0,1,2" : fondoActual.r_descripcion;

  const splitFund = descriptionFund.split(",");

  splitFund.push(String(fondoOrigen));

  const filterFund = info.filter((item) => splitFund.includes(String(item.id)));

  const filterFund0 = filterFund.filter((item) => item.id === 0);
  return (
    <>
      <Box sx={{ fontSize: "10px", pl: 2, pb: 2 }}>
        <Carousel
          sx={{ minWidth: "50px" }}
          onChange={(e) => {
            if (filterFund0.length === 0) {
              OptionSelect(e + 1);
            } else {
              OptionSelect(e);
            }
          }}
          autoPlay={false}
          cycleNavigation={true}
          index={fondoOrigen}
          navButtonsAlwaysVisible={true}
          activeIndicatorIconButtonProps={{
            style: {
              color: "#CE1336",
            },
          }}
          navButtonsProps={{
            className: "hide-hover",
            style: {
              backgroundColor: "unset",
              color: "#8C9195",
              margin: 0,
              padding: "16px 0",
            },
          }}
        >
          {filterFund.map((item) => (
            <Box
              key={item.id}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              {fondoOrigen === item.id ? (
                <Chip
                  label="FONDO ACTUAL"
                  size="small"
                  sx={{ mb: 2, p: "4px 16px" }}
                />
              ) : opcionSeleccionada === item.id ? (
                <Chip
                  label="FONDO DESTINO"
                  size="small"
                  variant="outlined"
                  sx={{
                    mb: 2,
                    border: "none",
                    backgroundColor: "#CE1336",
                    color: "white",
                    p: "4px 10px",
                  }}
                />
              ) : (
                <Chip
                  label=""
                  size="small"
                  variant="outlined"
                  sx={{ mb: 2, border: "none", p: "4px 16px" }}
                />
              )}

              <Card
                sx={
                  opcionSeleccionada === item.id
                    ? styles.cardStyleChanges
                    : fondoOrigen === item.id
                    ? styles.cardAlReadySelectedStyle
                    : styles.cardStyle
                }
                onClick={() => {
                  if (fondoOrigen !== item.id) {
                    handleClick(item.id);
                  }
                }}
              >
                <Box sx={styles.cardContentStyle}>
                  <Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={fondoOrigen === item.id ? 12 : 10}>
                        <Chip
                          disabled={fondoOrigen === item.id ? true : false}
                          label={item.title}
                          size="small"
                          sx={{
                            p: "4px 10px",
                            backgroundColor:
                              opcionSeleccionada === item.id
                                ? "#CE1336"
                                : fondoOrigen === item.id
                                ? "#ACAFB3"
                                : "#2F6D95",
                            color: "white",
                            borderRadius: "8px",
                            "&.MuiChip-root.Mui-disabled": {
                              width: "100%",
                            },
                          }}
                        />
                      </Grid>
                      {fondoOrigen !== item.id && (
                        <Grid item xs={2} sx={styles.gridCheckboxStyle}>
                          {opcionSeleccionada === item.id ? (
                            <CheckCircleIcon
                              sx={{ color: "#CE1336" }}
                              fontSize="medium"
                              disabled={fondoOrigen === item.id ? true : false}
                            />
                          ) : (
                            <PanoramaFishEyeIcon
                              sx={{ color: "#ACAFB3" }}
                              fontSize="medium"
                            />
                          )}
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} sx={styles.gridRiesgoStyle}>
                      {fondoOrigen === item.id ? (
                        <Box sx={{ pt: 2 }}>
                          {item.id === 0 && <GraficCeroDisableIcon />}
                          {item.id === 1 && <GraficOneDisableIcon />}
                          {item.id === 2 && <GraficTwoDisableIcon />}
                          {item.id === 3 && <GraficThreeDisableIcon />}
                        </Box>
                      ) : (
                        <Box sx={{ pt: 2 }}>
                          {item.id === 0 && <GraficCeroActiveIcon />}
                          {item.id === 1 && <GraficOneActiveIcon />}
                          {item.id === 2 && <GraficTwoActiveIcon />}
                          {item.id === 3 && <GraficThreeActiveIcon />}
                        </Box>
                      )}
                    </Grid>
                    <Grid item xs={12} sx={{ pb: 0.5 }}>
                      <Grid
                        container
                        spaicing={2}
                        sx={{
                          p: 1,
                          bgcolor:
                            fondoOrigen === item.id ? "#F6F6F6" : "#F6F6F6",
                          borderRadius: "8px",
                          display: "flex",
                        }}
                      >
                        <Grid item xs={6}>
                          <Typography
                            sx={{
                              fontSize: "1.2em",
                            }}
                          >
                            Riesgo:
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Typography
                              sx={{ color: "#6E7378", fontSize: "1.2em" }}
                            >
                              {item.riesgo}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sx={styles.gridRentabilidadStyle}>
                      <Grid container spacing={2}>
                        <Grid item xs={screenXL ? (matches ? 6 : 6) : 6}>
                          <Typography
                            sx={{
                              fontSize: "1.2em",
                            }}
                          >
                            Potencial de rentabilidad:
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={screenXL ? (matches ? 6 : 6) : 6}
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                          }}
                        >
                          <Box>
                            <Typography
                              sx={{ color: "#6E7378", fontSize: "1.2em" }}
                            >
                              {item.rentabilidad}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Card>
            </Box>
          ))}
        </Carousel>
      </Box>
    </>
  );
};

export default BasicCard;
