import React, { useReducer } from "react";
import CambiofondoContext from "./cambiofondoContext";
import CambiofondoReducer from "./cambiofondoReducer";
import clienteAxios from "../../config/axios";
import {
  CAMBIO_FONDO_GET_EXITOSO,
  CAMBIO_FONDO_GET_ERROR,
  CAMBIO_FONDO_GET_LOADIN,
  CAMBIO_FONDO_CLEANER,
  CAMBIO_FONDO_CLEANER_PARCIAL,
  CAMBIO_FONDO_POST_EXITOSO,
  CAMBIO_FONDO_POST_ERROR,
  CAMBIO_FONDO_POST_LOADIN,
  ETARIO_ERROR,
  ETARIO_GET,
  ETARIO_RESETEAR,
  ETARIO_LOADING,
  ETARIO_POTS,
  ETARIO_CONNECT,
} from "../../types";

const CambiofondoState = (props) => {
  const initialState = {
    datosCambioFondo: null,
    mensajeAlerta: null,
    loadingPage: false,
    loadingBtn: false,
    datosResultadoCambio: null,
    validateEtareo: null,
    loadingEtareo: false,
    errorEtareo: null,
    conectEtareo: null,
  };

  const handleErrorsLocal = (error, origin_error) => {
    if (error.message === "Network Error") {
      dispatch({
        type: origin_error,
        payload: {
          code: 1, // error de red
          msg: "Su red presenta lentitud, por favor recargue la página.",
        },
      });
    } else {
      if (error.response?.status === 500) {
        dispatch({
          type: origin_error,
          payload: {
            code: 1, // su token a expirado
            msg: "Error en los servicios, por favor recargue la página.",
          },
        });
      } else if (error.response?.data?.errors?.message === "Unauthenticated.") {
        dispatch({
          type: origin_error,
          payload: {
            code: 2, // su token a expirado
            msg: "Su sesión ha expirado, por favor vuelva a iniciar sesión.",
          },
        });
      } else {
        console.log("entramos al default errors");
      }
    }
  };

  const obtenerDatosCambioFondo = async () => {
    dispatch({
      type: CAMBIO_FONDO_GET_LOADIN,
      payload: true,
    });
    try {
      const respuesta = await clienteAxios.get("/cambio-fondo");
      dispatch({
        type: CAMBIO_FONDO_GET_EXITOSO,
        payload: respuesta.data,
      });
    } catch (error) {
      handleErrorsLocal(error, CAMBIO_FONDO_GET_ERROR);
    }
  };

  const validacionEtario = async () => {
    // dispatch({
    //   type: ETARIO_LOADING,
    //   payload: true,
    // });
    try {
      const respuesta = await clienteAxios.get("/cambio-fondo/etario/validar");
      dispatch({
        type: ETARIO_GET,
        payload: respuesta.data,
      });

      return respuesta;
    } catch (error) {
      // handleErrorsLocal(error, ETARIO_ERROR);
    }
  };
  const clearValidacionEtario = async () => {
    dispatch({
      type: ETARIO_RESETEAR,
    });
  };
  const limpiarVariablesCambioFondo = () => {
    dispatch({
      type: CAMBIO_FONDO_CLEANER,
    });
  };
  const limpiarVariablesCambioFondoParcial = () => {
    dispatch({
      type: CAMBIO_FONDO_CLEANER_PARCIAL,
    });
  };

  const connectEtario = (value) => {
    dispatch({
      type: ETARIO_CONNECT,
      payload: value,
    });
  };

  const cambiarFondos = async (datos) => {
    dispatch({
      type: CAMBIO_FONDO_POST_LOADIN,
      payload: true, // mandamos el loading al boton
    });
    try {
      const respuesta = await clienteAxios.post("cambio-fondo/procesar", datos);
      dispatch({
        type: CAMBIO_FONDO_POST_EXITOSO,
        payload: respuesta.data,
      });
    } catch (error) {
      handleErrorsLocal(error, CAMBIO_FONDO_POST_ERROR);
    }
  };
  const anularFondos = async (datos) => {
    dispatch({
      type: CAMBIO_FONDO_POST_LOADIN,
      payload: true, // mandamos el loading al boton
    });
    try {
      const respuesta = await clienteAxios.post("cambio-fondo/rechazar", datos);
      dispatch({
        type: CAMBIO_FONDO_POST_EXITOSO,
        payload: respuesta.data,
      });
    } catch (error) {
      handleErrorsLocal(error, CAMBIO_FONDO_POST_ERROR);
    }
  };
  const cambiarFondoEtario = async (datos) => {
    dispatch({
      type: CAMBIO_FONDO_POST_LOADIN,
      payload: true, // mandamos el loading al boton
    });
    try {
      const respuesta = await clienteAxios.post(
        "/cambio-fondo/etario/actualiza-decision",
        datos
      );
      dispatch({
        type: CAMBIO_FONDO_POST_EXITOSO,
        payload: [respuesta.data.data],
      });
    } catch (error) {
      handleErrorsLocal(error, CAMBIO_FONDO_POST_ERROR);
    }
  };
  const [state, dispatch] = useReducer(CambiofondoReducer, initialState);
  return (
    <CambiofondoContext.Provider
      value={{
        datosCambioFondo: state.datosCambioFondo,
        mensajeAlerta: state.mensajeAlerta,
        loadingPage: state.loadingPage,
        loadingBtn: state.loadingBtn,
        datosResultadoCambio: state.datosResultadoCambio,
        validateEtareo: state.validateEtareo,
        conectEtareo: state.conectEtareo,
        obtenerDatosCambioFondo,
        limpiarVariablesCambioFondo,
        limpiarVariablesCambioFondoParcial,
        anularFondos,
        cambiarFondos,
        validacionEtario,
        cambiarFondoEtario,
        connectEtario,
        clearValidacionEtario,
      }}
    >
      {props.children}
    </CambiofondoContext.Provider>
  );
};

export default CambiofondoState;
