import React, { useState, useContext, useEffect } from "react";
import hocWidthWindow from "../../HOC/HocWidthWindow";
import "./index.css";
import Etario from "./components/Etario";
import ModalEtario from "./components/ModalEtario";
import {
  Grid,
  useTheme,
  useMediaQuery,
  Box,
  Typography,
  Paper,
  IconButton,
} from "@mui/material";
import Recordatorio from "./Recordatorio";
import PrimerPaso from "./PrimerPaso";
import SegundoPaso from "./SegundoPaso";
import Loading from "../../components/Loading/index.jsx";
import ReactGa from "react-ga";
import AuthContext from "../../context/autenticacion/authContext";
import CambiofondoContext from "../../context/cambiofondo/cambiofondoContext";
import ModalContext from "../../context/modal/modalContext";
import clienteAxios from "../../config/axios";
import DetailProcess from "./components/DetailProcess";
import HorizontalStepper from "./components/Stepper";
import BreadCrumb from "./components/BreadCrumb";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";

const CambioFondo = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const breakpointScreen = useMediaQuery("(max-width:980px)");

  /* estados para el stepper */
  const [stepLength, setStepLength] = useState([
    "Selección de tipo de fondo",
    "Confirmación de solicitud",
  ]);

  const [fondoDestino, setFondoDestino] = useState("");

  /* 1.- manejo del fondo actual el cual será cambiado */
  const [fondoActual, setFondoActual] = useState({
    r_aporte: "",
    r_aplica: "",
    r_saldo: "",
    r_fondo_origen: "",
    r_fondo_destino: null,
    r_estado: null,
    r_descripcion: "",
    r_tipo_aporte: "",
    r_fecha_solicitud: null,
    r_num_formulario: null,
  });

  const [showStatus, setShowStatus] = useState({
    r_aporte: "",
    r_descripcion: "",
    r_estado: null,
    r_fecha_solicitud: null,
    r_fondo_destino: "",
    r_fondo_origen: 0,
    r_num_formulario: null,
    r_fecha_maxima_cancelacion: null,
    r_fecha_estimada: "",
    r_saldo: "",
    r_tipo_aporte: "",
  });

  /* estado para los pasos */
  const [step, setStep] = React.useState(0);
  const [activeButton, setActiveButton] = React.useState(false);
  //Obtenemos los state
  const authContext = useContext(AuthContext);
  const { actualizarTimer, birthday } = authContext;

  const cambiofindoContext = useContext(CambiofondoContext);
  const {
    datosCambioFondo,
    mensajeAlerta,
    obtenerDatosCambioFondo,
    limpiarVariablesCambioFondoParcial,
    cambiarFondos,
    loadingBtn,
    anularFondos,
    datosResultadoCambio,
    cambiarFondoEtario,
    validateEtareo,
    validacionEtario,
    conectEtareo,
    connectEtario,
    clearValidacionEtario,
  } = cambiofindoContext;

  const modalContext = useContext(ModalContext);
  const { setMessage } = modalContext;

  const [completed, setCompleted] = useState(true);
  const [mostrarMensajeInfo, setMostrarMensajeInfo] = useState(false);
  const [messageErro, setMessageErro] = useState(false);

  const [mostrarModalToken, setMostrarModalToken] = useState(null);
  const [fondoFinal, setFondoFinal] = useState();
  const [closeProcess, setCloseProcess] = useState(false);
  const [valueResult, setValueResult] = useState(null);
  const [openModalToken, setopenModalToken] = useState(false);
  const [etario, setEtario] = useState(false);
  const [etarioDetail, setEtarioDaetail] = useState(false);

  const [open, setOpen] = useState(false);
  const [showEtario, setShowEtario] = useState(false);

  const client = localStorage.getItem("client");
  const docNum = localStorage.getItem("doc_num");
  const parseClient = JSON.parse(client);
  const age = moment().diff(birthday, "years");
  const filterAge =
    age < 25
      ? "Menores de 25"
      : age >= 25 && age < 35
      ? "25 A 34"
      : age >= 35 && age < 51
      ? "35 a 51"
      : age >= 51 && age < 65
      ? "51 a 65"
      : "Mayores de 65";

  const [tipoDeTramite, setTipoDeTramite] = useState("Cambio de tipo de fondo");

  useEffect(() => {
    window.scrollTo(0, 0);
    const request = async () => {
      const respuestaEtario = await validacionEtario();
      const code = respuestaEtario?.data?.code;
      const tipo_etario = respuestaEtario?.data?.tipo_etario;

      if (code === "aplica") {
        setTipoDeTramite(`Cambio de tipo de fondo - Etario ${tipo_etario}`);
      }
      // console.log({ respuestaEtario, code, age });
    };

    request();
  }, []);

  useEffect(() => {
    loadNPSData();
  }, [tipoDeTramite]);

  // useEffect(() => {
  //   if (validateEtareo) {
  //     if (validateEtareo.code === "aplica") {
  //       setEtario(true);
  //     }
  //   }
  // }, [validateEtareo]);

  useEffect(() => {
    //si hay un error mostraremos un mensaje
    if (mensajeAlerta) {
      mostrarMensaje();
    } else {
      ReactGa.pageview(window.location.href);
      obtenerDatosCambioFondo();
    }

    return () => {
      limpiarVariablesCambioFondoParcial();
      actualizarTimer();
    };

    // eslint-disable-next-line
  }, [mensajeAlerta]);

  useEffect(() => {
    if (datosResultadoCambio) {
      if (datosResultadoCambio[0].result === -1) {
        setMostrarMensajeInfo(false);
        setMostrarModalToken(true);
        setMessageErro(datosResultadoCambio);
        setopenModalToken(false);
      } else if (datosResultadoCambio[0].result === 1) {
        let obj = {
          r_aporte: fondoActual.r_aporte,
          r_descripcion: "",
          r_estado: "EN TRAMITE",
          r_fecha_solicitud: datosResultadoCambio[0].fecha,
          r_fondo_destino: datosResultadoCambio[0].fondo_destino,
          r_fondo_origen: datosResultadoCambio[0].fondo_origen,
          r_num_formulario: datosResultadoCambio[0].message,
          r_fecha_estimada: datosResultadoCambio[0].r_fecha_estimada,
          r_fecha_maxima_cancelacion:
            datosResultadoCambio[0].r_fecha_maxima_cancelacion,
          r_saldo: fondoActual.r_saldo,
          r_tipo_aporte: fondoActual.r_tipo_aporte,
        };
        setShowStatus(obj);
        setopenModalToken(null);
        setValueResult(datosResultadoCambio[0].result);
        setStep(2);
        loadNPS();
      } else if (datosResultadoCambio[0].result === 4) {
        setStep(2);
        let obj = {
          r_aporte: datosResultadoCambio[0].products,
          r_descripcion: "",
          r_estado: datosResultadoCambio[0].r_estado,
          r_fecha_solicitud: datosResultadoCambio[0].fecha_decision,
          r_fondo_destino: datosResultadoCambio[0].fondo_destino,
          r_fondo_origen: "",
          r_num_formulario: "",
          r_fecha_maxima_cancelacion:
            datosResultadoCambio[0].r_fecha_maxima_cancelacion,
          r_fecha_estimada: datosResultadoCambio[0].fecha_estimada,
          r_saldo: "",
          r_tipo_aporte: "",
        };
        // setMostrarMensajeInfo(true);
        setEtarioDaetail(true);
        connectEtario(false);
        setShowStatus(obj);
        setopenModalToken(null);
        setValueResult(datosResultadoCambio[0].result);
        loadNPS();
      } else if (datosResultadoCambio[0].result === 2) {
        setMostrarMensajeInfo(true);
        setopenModalToken(null);
        setValueResult(datosResultadoCambio[0].result);
        loadNPS();
        // setShowStatus(showStatus);
      }
    }
    // eslint-disable-next-line
  }, [datosResultadoCambio]);

  const loadNPS = () => {
    window.dispatchEvent(
      new CustomEvent("smile/feedback/show-poll", { detail: { id: 814 } })
    );
  };

  const loadNPSData = () => {
    // console.log({
    //   tipoDeTramite,
    //   validateEtareo,
    //   aplica: validateEtareo?.code === "aplica",
    //   etario,
    //   filterAge,
    //   age,
    //   tipo_de_tramite:
    //     validateEtareo?.code === "aplica"
    //       ? `Cambio de tipo de fondo - Etario ${age}`
    //       : "Cambio de tipo de fondo",
    // });

    window.dispatchEvent(
      new CustomEvent("smile/user-context/update", {
        detail: {
          tipo_de_tramite: tipoDeTramite,
          nombre_de_usuario: parseClient.r_primer_nombre,
          apellido_de_usuario: parseClient.r_primer_apellido,
          documento_de_identidad: docNum,
          correo: parseClient.r_correo,
          telefono: parseClient.r_celular,
          segmento: parseClient.r_segmento,
          grupo_de_edad: filterAge,
        },
      })
    );
  };
  const mostrarMensaje = () => {
    setMessage(mensajeAlerta);
  };

  const procesarCambio = (datos) => {
    cambiarFondos(datos);
  };

  if (mensajeAlerta !== null) return <p></p>;
  if (datosCambioFondo === null || validacionEtario === null)
    return <Loading />;

  const handleArrowClick = (option) => {
    if (option === "1") {
      setFondoDestino("");
      setFondoActual({
        r_aporte: "",
        r_descripcion: "",
        r_estado: null,
        r_fecha_solicitud: null,
        r_fondo_destino: "",
        r_fondo_origen: 0,
        r_num_formulario: null,
        r_saldo: "",
        r_tipo_aporte: "",
      });
    }
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#F8F9FB",
          width: "100%",
          height: breakpointScreen && "50px",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          p: breakpointScreen && 2,
        }}
      >
        {fondoActual.r_aporte === "" && breakpointScreen && <BreadCrumb />}

        {fondoActual.r_aporte !== "" && breakpointScreen && (
          <>
            {showStatus.r_estado === null ? (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <IconButton onClick={() => handleArrowClick("1")}>
                  <ArrowBackIcon color="primary" />
                </IconButton>
                <Typography
                  sx={{
                    color: "#CE1336",
                    fontWeight: "600px",
                    fontSize: "12px",
                  }}
                >
                  Cambio de tipo de fondo
                </Typography>
                <IconButton>
                  <CloseIcon
                    color="primary"
                    onClick={() => setCloseProcess(true)}
                  />
                </IconButton>
              </Box>
            ) : (
              ""
            )}
          </>
        )}
      </Box>

      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Paper
          sx={{
            p: 2,
            m: !matches && 2,
            height: "100%",
            maxWidth: conectEtareo ? "1051px" : "unset",
            width: "100%",
          }}
        >
          {/* {conectEtareo ? ( */}
          {conectEtareo ? (
            <Etario
              setEtario={setEtario}
              cambiarFondoEtario={cambiarFondoEtario}
              datosCambioFondo={datosCambioFondo}
              setFondoActual={setFondoActual}
              setStep={setStep}
              validateEtareo={validateEtareo}
              validacionEtario={connectEtario}
            />
          ) : (
            <>
              {fondoActual.r_aporte !== "" && (
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ pb: 2, maxWidth: "280px" }}>
                    <HorizontalStepper completed={completed} step={step} />
                  </Box>
                </Box>
              )}

              <Grid container spacing={2}>
                <Grid item xs={12} lg={12}>
                  <>
                    {!mostrarMensajeInfo && showStatus.r_estado === null && (
                      <PrimerPaso
                        procesarCambio={procesarCambio}
                        datosCambioFondo={datosCambioFondo}
                        loadingBtn={loadingBtn}
                        mostrarModalToken={mostrarModalToken}
                        setopenModalToken={setopenModalToken}
                        openModalToken={openModalToken}
                        setMostrarModalToken={setMostrarModalToken}
                        setFondoActual={setFondoActual}
                        fondoActual={fondoActual}
                        cambiarFondoEtario={cambiarFondoEtario}
                        step={step}
                        setStep={setStep}
                        setOpenEtario={setOpen}
                        birthday={birthday}
                        fondoDestino={fondoDestino}
                        setFondoDestino={setFondoDestino}
                        activeButton={activeButton}
                        setActiveButton={setActiveButton}
                        fondoFinal={fondoFinal}
                        setFondoFinal={setFondoFinal}
                        validateEtareo={validateEtareo}
                        setMostrarMensajeInfo={setMostrarMensajeInfo}
                        messageErro={messageErro}
                        closeProcess={closeProcess}
                        setCloseProcess={setCloseProcess}
                        setShowStatus={setShowStatus}
                        setValueResult={setValueResult}
                        etario={etario}
                        setEtario={setEtarioDaetail}
                      />
                    )}

                    {showStatus.r_estado !== null && (
                      <DetailProcess
                        datos={showStatus}
                        datosResultadoCambio={datosResultadoCambio}
                        anularFondos={anularFondos}
                        setFondoActual={setFondoActual}
                        procesarCambio={procesarCambio}
                        datosCambioFondo={datosCambioFondo}
                        loadingBtn={loadingBtn}
                        mostrarModalToken={mostrarModalToken}
                        setopenModalToken={setopenModalToken}
                        openModalToken={openModalToken}
                        setMostrarModalToken={setMostrarModalToken}
                        fondoActual={fondoActual}
                        step={step}
                        etario={etarioDetail}
                        setStep={setStep}
                        fondoDestino={fondoDestino}
                        setFondoDestino={setFondoDestino}
                        activeButton={activeButton}
                        setActiveButton={setActiveButton}
                        fondoFinal={fondoFinal}
                        setFondoFinal={setFondoFinal}
                        messageErro={messageErro}
                        setMostrarMensajeInfo={setMostrarMensajeInfo}
                        closeProcess={closeProcess}
                        setCloseProcess={setCloseProcess}
                        setShowStatus={setShowStatus}
                        valueResult={valueResult}
                        validacionEtario={clearValidacionEtario}
                      />
                    )}
                  </>
                </Grid>
              </Grid>
            </>
          )}
        </Paper>
      </Box>
      {validateEtareo && (
        <ModalEtario
          open={open}
          setOpen={setOpen}
          connectEtario={connectEtario}
          validateEtareo={validateEtareo}
        />
      )}
    </>
  );
};

export default hocWidthWindow(CambioFondo);
