import React, { useState, useEffect } from "react";
import "./index.css";
import habi from "../../../../assets/icons/habi.png";
import habi_cuerpo from "../../../../assets/icons/habi-cuerpo.png";
import close_habi from "../../../../assets/icons/close-habi.png";
import whatsapp from "../../../../assets/icons/widget_whatsapp.png";
import call_center from "../../../../assets/icons/widget_call_center.png";
import central from "../../../../assets/icons/widget_central.png";
import chat from "../../../../assets/icons/widget_chat.png";
import vector from "../../../../assets/icons/vector.png";

const ContacWidget = () => {
  const [isWidgetOpen, setIsWidgetOpen] = useState(false);
  const [isMessageOpen, setIsMessageOpen] = useState(true);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (
        event.ctrlKey &&
        (event.key === "m" || event.key === "M" || event.key === 77)
      ) {
        event.preventDefault();
        setIsWidgetOpen(true);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <>
      <div
        className={
          isWidgetOpen
            ? "hp_chat__menu hp-animation hp-visible"
            : "hp_chat__menu hp-animation"
        }
      >
        <div className="hp-habi-cuerpo">
          <img src={habi_cuerpo} alt="habi-cuerpo" />
        </div>
        <div
          className="hp-close__menu hp-button__animation"
          onClick={() => {
            setIsWidgetOpen(false);
          }}
        >
          <img
            src="https://www.afphabitat.com.pe/widget_whatsapp/img/close.png"
            alt="close-icon"
          />
        </div>
        <div className="hp-chat__menu--title">
          <h2>Comunícate con nuestros canales de atención ágiles y rápidos</h2>
        </div>
        <div className="hp-chat__menu--container">
          <div className="hp-network__container">
            <div className="hp-network__title">
              <h2>Canales digitales 24x7</h2>
            </div>
            {/* <div className="hp-network__item" style={{ cursor: 'pointer' }} onClick={() => window.open('https://m.me/afphabitatperu')}>
                            <div className="hp-network__image">
                                <img src={chat} alt="chat-icon" />
                            </div>
                            <div className="hp-network__item_content" >
                                <p>Chatbot Habi</p>
                                <div className="hp-directory__container" >
                                    <div className="hp-directory__item">Chatea con Habi y te atenderá enseguida</div>
                                </div>
                            </div>
                            <div className="hp-network__vector">
                                <img src={vector} alt="vector-icon" />
                            </div>
                        </div> */}
            <div
              className="hp-network__item hp-network--whatsapp"
              style={{ cursor: "pointer" }}
              onClick={() =>
                window.open(
                  "https://api.whatsapp.com/send?phone=51981211004&text=Hola+AFP+Habitat%2C+quiero+realizar+una+consulta.&app_absent=0"
                )
              }
            >
              <div className="hp-network__image">
                <img src={whatsapp} alt="whatsapp-icon" />
              </div>
              <div className="hp-network__item_content">
                <p>Chatbot Habi - Whatsapp</p>
                <div className="hp-directory__container">
                  <div className="hp-directory__item">+51 981 211 004</div>
                </div>
              </div>
              <div className="hp-network__vector">
                <img src={vector} alt="vector-icon" />
              </div>
            </div>
          </div>
        </div>
        <div className="hp-chat__menu--container">
          <div className="hp-network__container">
            <div className="hp-network__title">
              <h2>Canales telefónicos</h2>
            </div>
            <div
              className="hp-network__item"
              style={{ cursor: "pointer" }}
              onClick={() => window.open("tel:012302200")}
            >
              <div className="hp-network__image">
                <img src={call_center} alt="call-center-icon" />
              </div>
              <div className="hp-network__item_content">
                <p>Contact Center Lima</p>
                <div className="hp-directory__container">
                  <div className="hp-directory__item">01 230-2200</div>
                </div>
              </div>
              <div className="hp-network__vector">
                <img src={vector} alt="vector-icon" />
              </div>
            </div>
            <div
              className="hp-network__item"
              style={{ cursor: "pointer" }}
              onClick={() => window.open("tel:080114224")}
            >
              <div className="hp-network__image">
                <img src={call_center} alt="call-center-icon" />
              </div>
              <div className="hp-network__item_content">
                <p>Contact Center Provincia</p>
                <div className="hp-directory__container">
                  <div className="hp-directory__item">0801-14-224</div>
                </div>
              </div>
              <div className="hp-network__vector">
                <img src={vector} alt="vector-icon" />
              </div>
            </div>
            <div
              className="hp-network__item"
              style={{ cursor: "pointer" }}
              onClick={() => window.open("tel:017013603")}
            >
              <div className="hp-network__image">
                <img src={central} alt="call-center-icon" />
              </div>
              <div className="hp-network__item_content">
                <p>Central de Retiro de Fondos</p>
                <div className="hp-directory__container">
                  <div className="hp-directory__item">01 701-3603</div>
                </div>
              </div>
              <div className="hp-network__vector">
                <img src={vector} alt="vector-icon" />
              </div>
            </div>
            {/* <div
              className="hp-network__item"
              style={{ cursor: "pointer" }}
              onClick={() => window.open("tel:017007847")}
            >
              <div className="hp-network__image">
                <img src={call_center} alt="call-center-icon" />
              </div>
              <div className="hp-network__item_content">
                <p>Central de consultas</p>
                <div className="hp-directory__container">
                  <div className="hp-directory__item">01 700-7847</div>
                </div>
              </div>
              <div className="hp-network__vector">
                <img src={vector} alt="vector-icon" />
              </div>
            </div> */}
          </div>
        </div>
      </div>

      <div
        className={
          isWidgetOpen
            ? "hp_chat__item hp-button__animation hp-animation hp-visible"
            : "hp_chat__item hp-button__animation hp-animation"
        }
        style={{ cursor: "pointer" }}
      >
        <div
          className={`hp_chat__message ${
            isMessageOpen ? "show__message" : "hide__message"
          }`}
        >
          <img
            className="hp_chat_close_message"
            src={close_habi}
            alt="close-icon"
            onClick={() => setIsMessageOpen(!isMessageOpen)}
          />
          <span className="content">Hola, soy Habi</span>
        </div>
        <img src={habi} alt="habi-icon" onClick={() => setIsWidgetOpen(true)} />
      </div>
    </>
  );
};

export default ContacWidget;
