import React, { useState, useContext, useEffect } from "react";
import { FormGroup, Label } from "reactstrap";

import EcmensualContext from "../../context/estadocuentamensual/ecmensualContext";
import AuthContext from "../../context/autenticacion/authContext";
import ModalContext from "../../context/modal/modalContext";
import ReactGa from "react-ga";
import NoDisponible from "../../components/NoDisponible/index.jsx";
import Loading from "../../components/Loading/index.jsx";
import Grid from "@mui/material/Grid";
import { CardContainer, Page, Button } from "../../components";
import "./index.css";

const EstadoCuentaMensual = (props) => {
  //Obtenemos los state del home
  const ecmensualContext = useContext(EcmensualContext);
  const {
    listaDeOpciones,
    externalLink,
    mensajeAlerta,
    loadingBtn,
    obtenerDatosListaMeses,
    obtenerECMensual,
    limpiarVariablesECMensualParcial,
    limpiarExternalLink,
  } = ecmensualContext;

  const modalContext = useContext(ModalContext);
  const { setMessage } = modalContext;

  const authContext = useContext(AuthContext);
  const { actualizarTimer } = authContext;

  const [mes, guardarMes] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    //si hay un error mostraremos un mensaje
    if (mensajeAlerta) {
      mostrarMensaje();
    } else {
      ReactGa.pageview(window.location.href);
      obtenerDatosListaMeses();
    }

    return () => {
      limpiarVariablesECMensualParcial();
      actualizarTimer();
    };

    // eslint-disable-next-line
  }, [mensajeAlerta]);

  useEffect(() => {
    if (externalLink) {
    }
  }, [externalLink]);

  const mostrarMensaje = () => {
    setMessage(mensajeAlerta);
  };

  const handleSelectMes = (e) => {
    limpiarExternalLink();
    if (e.target.value.length > 0) {
      let item = JSON.parse(e.target.value);
      guardarMes(item.r_periodo);
    } else {
      guardarMes(null);
    }
  };

  const consultar = (e) => {
    e.preventDefault();
    let bodyFormData = new FormData();
    bodyFormData.set("period", mes);
    obtenerECMensual(bodyFormData);
  };

  const activarBtn =
    mes && !loadingBtn
      ? { class: "", status: false }
      : { class: "disable", status: true };

  if (mensajeAlerta !== null) return <p></p>;
  if (listaDeOpciones === null) return <Loading />;
  if (listaDeOpciones.length === 0)
    return <NoDisponible mensaje={"No dispone con estado de cuenta mensual"} />;

  return (
    <Page title="Estado de cuenta" showHeader={"mobile"}>
      <CardContainer>
        <Grid container>
          <Grid item xs={12} lg={12}>
            <p>
              Puedes ver el estado de cuenta del mes que desees, solo tienes que
              indicar el mes y consultarlo aquí.
            </p>
          </Grid>

          <Grid item xs={12} lg={3} pr={1}>
            <FormGroup className="mb-0">
              <Label for="doc_number">Seleccione el periodo</Label>
              <select
                className="form-control"
                type="select"
                name="doc_type"
                id="doc_type"
                onChange={handleSelectMes}
              >
                <option value={""}>-periodo-</option>
                {listaDeOpciones.map((item, index) => (
                  <option key={index} value={JSON.stringify(item)}>
                    {item.r_descripcion}
                  </option>
                ))}
              </select>
            </FormGroup>
          </Grid>
          <Grid item xs={12} lg={6} pr={1}>
            <FormGroup className="mb-0 form-new">
              <Button disabled={activarBtn.status} onClick={consultar}>
                <span>Consultar </span>
                {loadingBtn && (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
              </Button>
            </FormGroup>
          </Grid>
        </Grid>
      </CardContainer>
    </Page>
  );
};

export default EstadoCuentaMensual;
