import React, { useState, Fragment, useContext } from "react";
import { Card, CardBody } from "reactstrap";
import CustomModal from "../../components/custromModal/CustomModal";
import {
  Box,
  useTheme,
  useMediaQuery,
  Grid,
  Paper,
  Typography,
  Button,
  Checkbox,
  FormControlLabel,
  Dialog,
  Slide,
  DialogContent,
  DialogTitle,
  IconButton,
  DialogActions,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import ModalErrorToken from "./ModalErrorToken";
import SwipeEdge from "./components/SwipeEdge";
import { NotFoundData } from "../../components";
import hocWidthWindow from "../../HOC/HocWidthWindow";

/* componentes de la vista */
import ProductCard from "./components/ProductCard";
import TypeFondoCard from "./components/TypeFondoCard";
import ValidateToken from "../../components/validateToken";

/* iconos */
import CoinsIcon from "../../assets/IconBucket/Icon/CoinsIcon";
import SavingIcon from "../../assets/IconBucket/Icon/SavingIcon";
import CardFondoSelected from "./components/CardFondoSelected";

/* material icon */
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import CloseIcon from "@mui/icons-material/Close";
import codeContext from "../../context/code/codeContext";
import BasicCard from "./components/BasicCard";
import BasicCardEtero from "./components/BasicCardEtareo";

import CardOne from "./components/CardOne";
import CardTwo from "./components/CardTwo";
import { useHistory } from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const screenHeight = window.innerHeight;
const screenWidth = window.innerWidth;

const height =
  screenHeight <= 667
    ? 0
    : screenWidth >= 360 && screenWidth <= 400
    ? screenHeight - 680
    : screenHeight - 620;
const heightToken =
  screenHeight <= 667
    ? 0
    : screenWidth >= 360 && screenWidth <= 400
    ? screenHeight - 615
    : screenHeight - 625;
const heightDialog = screenHeight - 290;

const useStyles = makeStyles({
  DialogStyle: {
    "& .MuiDialog-paper": {
      borderRadius: "16px",
    },
  },
  DialogStyleChages: {
    "& .MuiDialog-container": {
      justifyContent: "flex-end",
      alignItems: "flex-end",
    },
    "& .MuiDialog-paper": {
      borderRadius: "16px 16px 0 0",
      maxHeight: "95%",
      height: "unset",
    },
  },
  DialogTokenStyle: {
    "& .MuiDialog-paper": {
      borderRadius: screenHeight <= 667 ? "0px" : "16px 16px 0 0",
      top: `${heightToken}px !important`,
    },
  },
  DialoMessageStyle: {
    "& .MuiDialog-paper": {
      borderRadius: "16px",
      top: `${heightDialog}px !important`,
    },
  },
});

export const DataTipoFondo = [
  {
    id: 0,
    title: "Fondo 0 - Protegido",
    rentabilidad: "Mínimo",
    riesgo: "Mínimo",
    name: `Fondo 0`,
    descripcionUno: "Te encuentras cerca de la edad de jubilación.",
    descripcionDos: "No te puedes permitir perdidas en tu capital.",
    descripcionTres: "Tienes 60 años o más.",
    rentaFija: "100%",
  },
  {
    id: 1,
    title: "Fondo 1 - Conservador",
    rentabilidad: "Baja",
    riesgo: "Baja",
    name: `Fondo 1`,
    descripcionUno:
      "Tienes poca tolerancia a los riesgos y fluctuaciones del mercado.",
    descripcionDos: "Buscas proteger tu capital.",
    descripcionTres: "Tienes 60 años o más y estás próximo a jubilarte.",
    nacional: "69.60%",
    extranjero: "33.95%",
    transito: "-3.55%",
    rentaFija: "90.15%",
    rentaVariable: "9.85",
  },
  {
    id: 2,
    title: "Fondo 2 - Moderado",
    rentabilidad: "Moderada",
    riesgo: "Moderada",
    name: `Fondo 2`,
    descripcionUno:
      "Tienes una tolerancia moderada a los riesgos y fluctuaciones del mercado.",
    descripcionDos: "Buscas obtener retorno.",
    descripcionTres: "Tienes entre 40 a 60 años.",
    nacional: "48.61%",
    extranjero: "53.29%",
    transito: "-1.90%",
    rentaFija: "38.20%",
    rentaVariable: "43.20%",
    alternativo: "18.60%",
  },
  {
    id: 3,
    title: "Fondo 3 - Arriesgado",
    rentabilidad: "Alta",
    riesgo: "Alta",
    name: `Fondo 3`,
    descripcionUno:
      "Tienes alta tolerancia a los riesgos y fluctuaciones del mercado.",
    descripcionDos: "Buscas un mayor retorno a largo plazo.",
    descripcionTres: "Tienes 40 años o menos. ",
    nacional: "58.09%",
    extranjero: "43.11%",
    transito: "-1.20%",
    rentaFija: "13.80%",
    rentaVariable: "72.11%",
    alternativo: "14.09%",
  },
];

/* Data para las card de cada tipo de fondo */
export const InfoCard = [
  {
    id: 0,
    title: "Fondo 0 - Protegido",
    rentabilidad: "Mínimo",
    riesgo: "Mínimo",
    name: `Fondo 0`,
  },
  {
    id: 1,
    title: "Fondo 1 - Conservador",
    rentabilidad: "Baja",
    riesgo: "Baja",
    name: `Fondo 1`,
  },
  {
    id: 2,
    title: "Fondo 2 - Moderado",
    rentabilidad: "Moderada",
    riesgo: "Moderada",
    name: `Fondo 2`,
  },

  {
    id: 3,
    title: "Fondo 3 - Arriesgado",
    rentabilidad: "Alta",
    riesgo: "Alta",
    name: `Fondo 3`,
  },
];

const PrimerPaso = (props) => {
  const {
    step,
    setStep,
    datosCambioFondo,
    procesarCambio,
    mostrarModalToken,
    openModalToken,
    setopenModalToken,
    setMostrarModalToken,
    setFondoActual,
    fondoActual,
    fondoDestino,
    setFondoDestino,
    activeButton,
    setActiveButton,
    setFondoFinal,
    messageErro,
    setMostrarMensajeInfo,
    closeProcess,
    setCloseProcess,
    setShowStatus,
    birthday,
    etario,
    setValueResult,
    setOpenEtario,
    cambiarFondoEtario,
    setEtario,
    validateEtareo,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery("(min-width:1500px)");
  const mediaScreen = useMediaQuery("(max-width:1364px)");
  const smallScreenLg = useMediaQuery(theme.breakpoints.down("lg"));
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const smallScreenSm = useMediaQuery(theme.breakpoints.down("sm"));
  const desktop = useMediaQuery(theme.breakpoints.up("md"));
  const [open, setOpen] = React.useState(false);
  const history = useHistory();

  const [changeSwipeableState, setChangeSwipeableState] = React.useState(true);

  /*2.- estado para el manejo del fondo seleccionado, ase usa al momento de hacer swipe de una card a otra */
  const [swipeState, setSwipeState] = useState("");

  const [requestInfoDialog, setRequestInfoDialog] = useState(false);
  const handleClose = () => {
    setopenModalToken(false);
  };
  const handleDialogCondition = () => {
    setOpen(false);
  };
  const handleDialogToken = () => {
    setopenModalToken(false);
  };

  const [dataLocal, setDataLocal] = useState(
    datosCambioFondo.filter((item) => item.r_descripcion !== null)
  );
  const [mensaje, setMensaje] = useState(null);

  const [modalConfirmacionOpen, setModaConfirmacionOpen] = useState({
    open: false,
    info: null,
  });

  const handleSelect = (actual, destino) => {
    //let option = "JSON.parse(e.target.value)";
    let option = { item: { ...actual }, valor: destino.toString() };

    let nuevaDataLocal = dataLocal.map((item) => {
      if (item.r_aporte === option.item.r_aporte) {
        if (parseInt(option.valor) !== -1) {
          return {
            ...item,
            r_fondo_destino: option.valor,
          };
        } else {
          return {
            ...item,
            r_fondo_destino: null,
          };
        }
      } else {
        return item;
      }
    });

    setDataLocal(nuevaDataLocal);
    setMensaje(null);
  };

  const validarFormularioSelectAll = () => {
    let initialValue = 0;
    let opcionesSeleccionadas = dataLocal.reduce((total, currenValue) => {
      if (
        currenValue.r_fondo_destino === null ||
        currenValue.r_fondo_destino === undefined
      ) {
        return total + 1;
      } else {
        return total;
      }
    }, initialValue);

    if (dataLocal.length === opcionesSeleccionadas) {
      return false;
    } else {
      return true;
    }
  };

  const guardarCambio = () => {
    if (validarFormularioSelectAll()) {
      let arrayFiltrado = dataLocal.filter(
        (item) => item.r_fondo_destino != null
      );
      let arrayFinal = arrayFiltrado.map((item) => {
        return {
          tipo_aporte:
            item.r_tipo_aporte === "VSF"
              ? "Fondo Libre Habitat"
              : item.r_tipo_aporte === "OBL"
              ? "Fondo de Jubilación"
              : "Aportes Voluntarios con fin Previsional",
          fondo_origen: item.r_fondo_origen,
          fondo_destino: item.r_fondo_destino,
        };
      });

      setModaConfirmacionOpen({ open: true, info: arrayFinal });
    } else {
      setMensaje("Seleccione al menos un cambio de fondo de algún producto");
    }
  };

  const aceptarProcesoModal = (token) => {
    let arrayFiltrado = dataLocal.filter(
      (item) => item.r_fondo_destino != null
    );

    let sendData = {};
    let arrayFinal = arrayFiltrado.map((item) => {
      return {
        tipo_aporte: item.r_tipo_aporte,

        fondo_origen: item.r_fondo_origen,
        fondo_destino: item.r_fondo_destino,
      };
    });

    sendData.product = arrayFinal;
    sendData.v_token_digital = token;
    procesarCambio(sendData);
  };
  const aceptarProcesoModalEtario = (token) => {
    const filterProductVCF = datosCambioFondo.filter(
      (item) => item.r_tipo_aporte === "VCF"
    );

    let sendData = {
      obl_destino: fondoDestino,
      vcf_destino: filterProductVCF.length === 0 ? "" : fondoDestino,
      ind_decision: "P",
    };

    sendData.v_token_digital = token;
    cambiarFondoEtario(sendData);
  };
  const filterTipo = DataTipoFondo.filter(
    (item) => item.id === Number(swipeState)
  );
  const filterProduct = datosCambioFondo.filter(
    (item) => item.r_tipo_aporte !== "VSF"
  );

  return (
    <>
      <Paper>
        <Grid container spacing={2}>
          {datosCambioFondo.length > 0 && (
            <Grid item xs={12}>
              <Box
                sx={{ display: "flex", justifyContent: "space-between", pb: 2 }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                  }}
                >
                  <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
                    Cambio de tipo de fondo
                  </Typography>
                  {fondoActual.r_aporte !== "" && step >= 0 ? (
                    <Typography sx={{ fontSize: "14px" }}>
                      Selecciona el tipo de fondo destino
                    </Typography>
                  ) : (
                    <Typography sx={{ fontSize: "14px" }}>
                      Selecciona el producto donde deseas cambiar el tipo de
                      fondo
                    </Typography>
                  )}
                </Box>
                {fondoActual.r_aporte !== "" && step >= 0 && desktop && (
                  <>
                    <Box
                      sx={{
                        border: "1px solid #EDEDED",
                        borderRadius: "16px",
                        padding: "8px 16px",
                        display: "flex",
                        flexDirection: mediaScreen && "column",
                        alignItems: "center",
                      }}
                    >
                      <Box sx={{ display: "flex" }}>
                        {fondoActual.r_aporte ===
                          "APORTE VOLUNTARIO CON FIN" && (
                          <CoinsIcon size={22} color="black" />
                        )}
                        {fondoActual.r_aporte === "APORTE OBLIGATORIO" && (
                          <SavingIcon size={22} color="black" />
                        )}
                        &nbsp;
                        <Typography
                          color="textColor.main"
                          sx={{ fontWeight: 500, fontSize: "16px" }}
                        >
                          {fondoActual.r_aporte === "APORTE OBLIGATORIO" &&
                            "Fondo de Jubilación"}
                          {fondoActual.r_aporte ===
                            "APORTE VOLUNTARIO CON FIN" &&
                            "Fondo con Fin Previsional"}
                          {fondoActual.r_aporte !==
                            "APORTE VOLUNTARIO CON FIN" &&
                            fondoActual.r_aporte !== "APORTE OBLIGATORIO" &&
                            "Fondo Libre Habitat"}
                        </Typography>
                      </Box>
                      {/* <Box>
                      <CardFondoSelected
                        fondoActual={fondoActual}
                        fondoDestino={fondoDestino}
                      />
                    </Box> */}
                    </Box>
                  </>
                )}
              </Box>
            </Grid>
          )}
          {fondoActual.r_aporte === "" && (
            <Grid item xs={12} sm={12} lg={12} xl={10}>
              <Grid
                container
                sx={{
                  justifyContent: datosCambioFondo.length === 0 ? "center" : "",
                }}
              >
                {datosCambioFondo.length === 0 ? (
                  <NotFoundData
                    mensaje={
                      <span>
                        Por el momento no puedes realizar un Cambio de tipo
                        fondo, ante cualquier duda puedes comunicarte con
                        nuestros canales de atención.
                      </span>
                    }
                    btnText={"Volver al inicio"}
                    url={"/mi-habitat-digital/afiliado/inicio"}
                    icon={true}
                  />
                ) : (
                  <ProductCard
                    setStep={setStep}
                    datosCambioFondo={datosCambioFondo}
                    defaultSelect="-1"
                    setSwipeState={setSwipeState}
                    selectOption={setFondoActual}
                    setShowStatus={setShowStatus}
                    setOpen={setOpenEtario}
                    setEtario={setEtario}
                  />
                )}
              </Grid>
            </Grid>
          )}

          {fondoActual.r_aporte !== "" && step === 0 && (
            <>
              {smallScreen ? (
                <Box sx={{ mb: 10 }}>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        maxWidth: "500px",
                      }}
                    >
                      {etario ? (
                        <BasicCardEtero
                          info={InfoCard}
                          defaultSelect="-1"
                          OptionSelect={setSwipeState}
                          buttonType="eligeFondo"
                          step={step}
                          etario={etario}
                          setFondoDestino={setFondoDestino}
                          setChangeSwipeableState={setChangeSwipeableState}
                          validateEtareo={validateEtareo}
                        />
                      ) : (
                        <BasicCard
                          fondoActual={fondoActual}
                          info={InfoCard}
                          defaultSelect="-1"
                          OptionSelect={setSwipeState}
                          buttonType="eligeFondo"
                          step={step}
                          etario={etario}
                          fondoOrigen={Number(fondoActual.r_fondo_origen)}
                          setFondoDestino={setFondoDestino}
                          setChangeSwipeableState={setChangeSwipeableState}
                        />
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sx={{ pl: " 16px" }}>
                    {filterTipo.map((item, index) => (
                      <Fragment key={index}>
                        {
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Typography
                                variant="h6"
                                sx={{ fontSize: "16px", fontWeight: "600" }}
                              >
                                Este fondo te conviene si
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Box
                                sx={{
                                  p: 1,
                                  borderRadius: "16px",
                                  backgroundColor: "white",
                                  border: "1.5px solid #CDCED0",
                                  borderRadius: "16px",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: "14px",
                                    display: "flex",
                                    alignItems: "center",
                                    paddingLeft: "8px",
                                  }}
                                >
                                  {item.descripcionUno}
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item xs={12}>
                              <Box
                                sx={{
                                  p: 1,
                                  borderRadius: "16px",
                                  backgroundColor: "white",
                                  border: "1.5px solid #CDCED0",
                                  borderRadius: "16px",
                                  minHeight: "64px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: "14px",
                                    display: "flex",
                                    alignItems: "center",
                                    paddingLeft: "8px",
                                  }}
                                >
                                  {item.descripcionDos}
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item xs={12}>
                              <Box
                                sx={{
                                  p: 1,
                                  borderRadius: "16px",
                                  backgroundColor: "white",
                                  border: "1.5px solid #CDCED0",
                                  borderRadius: "16px",
                                  minHeight: "64px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: "14px",
                                    display: "flex",
                                    alignItems: "center",
                                    paddingLeft: "8px",
                                  }}
                                >
                                  {item.descripcionTres}
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography
                                variant="h6"
                                sx={{ fontSize: "16px", fontWeight: "600" }}
                              >
                                En qué se invierte
                              </Typography>
                            </Grid>
                            {item.id !== 0 && (
                              <Grid item xs={12}>
                                {/* primera card o box */}
                                <Box
                                  sx={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <CardOne
                                    extranjero={item.extranjero}
                                    nacional={item.nacional}
                                    transito={item.transito}
                                  />
                                </Box>
                              </Grid>
                            )}

                            <Grid item xs={12}>
                              <Box
                                sx={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <CardTwo
                                  swipeState={swipeState}
                                  alternativo={item.alternativo}
                                  rentaFija={item.rentaFija}
                                  variable={item.rentaVariable}
                                />
                              </Box>
                            </Grid>
                          </Grid>
                        }
                      </Fragment>
                    ))}
                  </Grid>
                </Box>
              ) : (
                <Grid item xs={12}>
                  <TypeFondoCard
                    step={step}
                    birthday={birthday}
                    defaultSelect="-1"
                    data={DataTipoFondo}
                    fondoActual={fondoActual}
                    setFondoDestino={setFondoDestino}
                    fondoOrigen={Number(fondoActual.r_fondo_origen)}
                    etario={etario}
                    validateEtareo={validateEtareo}
                  />
                </Grid>
              )}
            </>
          )}
          {fondoActual.r_aporte !== "" && desktop && (
            <Grid item xs={12}>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    width: "49%",
                    pr: 1,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    variant="outlined"
                    sx={{
                      borderRadius: "16px",
                      width: "100%",
                      maxWidth: "240px",
                      display: "flex",
                      justifyContent: "center",
                      "&&:hover::before": {
                        color: "#CE1336",
                      },
                    }}
                    onClick={() => {
                      setFondoActual({
                        r_aporte: "",
                        r_descripcion: "",
                        r_estado: null,
                        r_fecha_solicitud: null,
                        r_fondo_destino: "",
                        r_fondo_origen: 0,
                        r_num_formulario: null,
                        r_saldo: "",
                        r_tipo_aporte: "",
                      });
                      setStep();
                      setFondoDestino("");
                    }}
                  >
                    <Typography>Volver</Typography>
                  </Button>
                </Box>
                <Box sx={{ width: "49%", pl: 1 }}>
                  <Button
                    disabled={fondoDestino === "" ? true : false}
                    onClick={() => {
                      setOpen(true);
                      handleSelect(fondoActual, fondoDestino);
                    }}
                    variant="contained"
                    sx={{
                      borderRadius: "16px",
                      width: "100%",
                      maxWidth: "240px",
                      display: "flex",
                      justifyContent: "center",
                      "&:hover": {
                        background: "#CE1336",
                        boxShadow: "none",
                      },
                    }}
                  >
                    <Typography>Siguiente</Typography>
                  </Button>
                </Box>
              </Box>
            </Grid>
          )}
        </Grid>
        {smallScreenLg && fondoActual.r_aporte !== "" && (
          <SwipeEdge
            option="1"
            fondoActual={fondoActual}
            fondoDestino={fondoDestino}
            step={step}
            info={datosCambioFondo}
            setStep={setStep}
            setFondoFinal={setFondoFinal}
            setFondoDestino={setFondoDestino}
            setActiveButton={setActiveButton}
            setFondoActual={setFondoActual}
            open={open}
            etario={etario}
            setOpen={setOpen}
            changeSwipeableState={changeSwipeableState}
            setChangeSwipeableState={setChangeSwipeableState}
          />
        )}
      </Paper>

      <Box>
        <Dialog
          className={
            smallScreenSm ? classes.DialogStyleChages : classes.DialogStyle
          }
          fullScreen={smallScreenSm}
          open={open}
          aria-labelledby="alert-dialog-title"
          TransitionComponent={Transition}
          keepMounted
          aria-describedby="alert-dialog-slide-description"
        >
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: " center" }}
          >
            <Box sx={{ maxWidth: "500px" }}>
              <DialogTitle>
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <IconButton
                    onClick={() => {
                      handleDialogCondition();
                      setActiveButton(false);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
                {etario ? (
                  <Typography
                    sx={{
                      fontSize: "20px",
                      fontWeight: 600,
                      textAlign: "center",
                    }}
                  >
                    Condiciones de cambio de tipo de fondo etario
                  </Typography>
                ) : (
                  <Typography
                    sx={{
                      fontSize: "20px",
                      fontWeight: 600,
                      textAlign: "center",
                    }}
                  >
                    Condiciones de cambio de tipo de fondo
                  </Typography>
                )}
              </DialogTitle>

              <DialogContent>
                <>
                  {etario ? (
                    <>
                      <Box
                        sx={{
                          backgroundColor: "#F8F9FB",
                          borderRadius: "16px",
                          p: 2,
                          mb: screenHeight <= 667 ? 1 : 2,
                        }}
                      >
                        <Typography
                          sx={{ textAlign: "justify", lineHeight: "18px" }}
                        >
                          Tu solicitud de cambio de tipo de fondo etario pasará
                          por un proceso de validación, cuando finalice te
                          enviaremos un correo electrónico informando el
                          resultado de dicha validación.
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          backgroundColor: "#F8F9FB",
                          borderRadius: "16px",
                          p: 2,
                          mb: screenHeight <= 667 ? 1 : 2,
                        }}
                      >
                        <Typography
                          sx={{ textAlign: "justify", lineHeight: "18px" }}
                        >
                          Se considerará el valor cuota vigente a la fecha de
                          aplicación de cada solicitud de tipo de fondo.
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          backgroundColor: "#F8F9FB",
                          borderRadius: "16px",
                          p: 2,
                          mb: screenHeight <= 667 ? 1 : 2,
                        }}
                      >
                        <Typography
                          sx={{ textAlign: "justify", lineHeight: "18px" }}
                        >
                          Podrás cambiar nuevamente el fondo de tus productos
                          una vez esté aplicado este cambio de fondo etario.
                        </Typography>
                      </Box>
                    </>
                  ) : (
                    <>
                      <Box
                        sx={{
                          backgroundColor: "#F8F9FB",
                          borderRadius: "16px",
                          p: 2,
                          mb: 2,
                        }}
                      >
                        <Typography
                          sx={{ textAlign: "justify", lineHeight: "18px" }}
                        >
                          El cambio de tipo de fondo se realizará al 6to día
                          útil del siguiente mes, una vez ingresada la
                          solicitud.
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          backgroundColor: "#F8F9FB",
                          borderRadius: "16px",
                          p: 2,
                          mb: screenHeight <= 667 ? 1 : 2,
                        }}
                      >
                        <Typography
                          sx={{ textAlign: "justify", lineHeight: "18px" }}
                        >
                          Tu solicitud de cambio de tipo de fondo pasará por un
                          proceso de validación, cuando finalice te enviaremos
                          un correo electrónico informando el resultado de dicha
                          validación.
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          backgroundColor: "#F8F9FB",
                          borderRadius: "16px",
                          p: 2,
                          mb: screenHeight <= 667 ? 1 : 2,
                        }}
                      >
                        <Typography
                          sx={{ textAlign: "justify", lineHeight: "18px" }}
                        >
                          Se considerará el valor cuota vigente a la fecha de
                          aplicación de cada solicitud de tipo de fondo.
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          backgroundColor: "#F8F9FB",
                          borderRadius: "16px",
                          p: 2,
                          mb: screenHeight <= 667 ? 1 : 2,
                        }}
                      >
                        <Typography
                          sx={{ textAlign: "justify", lineHeight: "18px" }}
                        >
                          Recuerda que si realizas un cambio de tipo de fondo en
                          tu cuenta de Fondo Libre Habitat (Aporte Voluntario
                          sin Fin Previsional), estará sujeto al pago del
                          impuesto a la renta por lo que se retendrá un 5% de la
                          rentabilidad que hayas podido generar. Dicho
                          porcentaje será transferido a SUNAT.
                        </Typography>
                      </Box>
                    </>
                  )}

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={activeButton ? true : false}
                        onClick={() => {
                          setActiveButton(!activeButton);
                        }}
                      />
                    }
                    label="Acepto las condiciones"
                  />

                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      pr: 2,
                      pl: 2,
                      pb: 2,
                    }}
                  >
                    <Box
                      component="div"
                      sx={{
                        display: "flex",
                        width: "50%",
                        "& button": { width: "100%" },
                      }}
                    >
                      <Button
                        disabled={!activeButton ? true : false}
                        onClick={() => {
                          handleSelect(fondoActual, fondoDestino);
                          setopenModalToken(true);
                          handleDialogCondition();
                          setActiveButton(false);
                          guardarCambio();
                        }}
                        variant="contained"
                        color="primary"
                        sx={{
                          "&:hover": {
                            background: "#CE1336",
                            boxShadow: "none",
                          },
                        }}
                      >
                        Aceptar
                      </Button>
                    </Box>
                  </Box>
                </>
              </DialogContent>
            </Box>
          </Box>
        </Dialog>

        {openModalToken && (
          <Dialog
            className={
              smallScreen ? classes.DialogStyleChages : classes.DialogStyle
            }
            fullScreen={smallScreen}
            open={openModalToken}
            TransitionComponent={Transition}
            keepMounted
            aria-describedby="alert-dialog-slide-description"
          >
            <Box
              sx={{ width: "100%", display: "flex", justifyContent: "center" }}
            >
              <Box>
                <DialogTitle sx={{ pb: 0 }}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <IconButton onClick={() => handleDialogToken()}>
                          <CloseIcon />
                        </IconButton>
                      </Box>
                    </Grid>
                  </Grid>
                </DialogTitle>
                <DialogContent>
                  <ValidateToken
                    setMostrarModalToken={setMostrarModalToken}
                    setMostrarMensajeInfo={setMostrarMensajeInfo}
                    children={
                      <Box
                        sx={{
                          border: "1px solid #EDEDED",
                          borderRadius: "16px",
                          p: 1,
                          display: "flex",
                          flexDirection: mediaScreen && "column",
                          alignItems: "center",
                        }}
                      >
                        {etario ? (
                          filterProduct.map((item) => {
                            return (
                              <Box>
                                <Box sx={{ display: "flex" }}>
                                  {item.r_aporte ===
                                    "APORTE VOLUNTARIO CON FIN" && (
                                    <CoinsIcon size={22} color="black" />
                                  )}
                                  {item.r_aporte === "APORTE OBLIGATORIO" && (
                                    <SavingIcon size={22} color="black" />
                                  )}
                                  &nbsp;
                                  <Typography
                                    color="textColor.main"
                                    sx={{ fontWeight: 500, fontSize: "16px" }}
                                  >
                                    {item.r_aporte === "APORTE OBLIGATORIO" &&
                                      "Fondo de Jubilación"}
                                    {item.r_aporte ===
                                      "APORTE VOLUNTARIO CON FIN" &&
                                      "Fondo con Fin Previsional"}
                                    {item.r_aporte !==
                                      "APORTE VOLUNTARIO CON FIN" &&
                                      item.r_aporte !== "APORTE OBLIGATORIO" &&
                                      "Fondo Libre Habitat"}
                                  </Typography>
                                </Box>
                                <Box>
                                  <CardFondoSelected
                                    fondoActual={item}
                                    fondoDestino={fondoDestino}
                                  />
                                </Box>{" "}
                              </Box>
                            );
                          })
                        ) : (
                          <Box>
                            <Box sx={{ display: "flex" }}>
                              <SavingIcon size={22} color="black" />
                              &nbsp;
                              <Typography
                                color="textColor.main"
                                sx={{ fontWeight: 500, fontSize: "16px" }}
                              >
                                {fondoActual.r_aporte ===
                                  "APORTE OBLIGATORIO" && "Fondo de Jubilación"}
                                {fondoActual.r_aporte ===
                                  "APORTE VOLUNTARIO CON FIN" &&
                                  "Fondo con Fin Previsional"}
                                {fondoActual.r_aporte !==
                                  "APORTE VOLUNTARIO CON FIN" &&
                                  fondoActual.r_aporte !==
                                    "APORTE OBLIGATORIO" &&
                                  "Fondo Libre Habitat"}
                              </Typography>
                            </Box>
                            <Box>
                              <CardFondoSelected
                                fondoActual={fondoActual}
                                fondoDestino={fondoDestino}
                              />
                            </Box>
                          </Box>
                        )}
                      </Box>
                    }
                    activeChildren={smallScreen ? true : false}
                    cerrarModal={() => {
                      setopenModalToken(false);
                    }}
                    guardarCode={(token) => {
                      if (etario) {
                        aceptarProcesoModalEtario(token);
                      } else {
                        aceptarProcesoModal(token);
                      }
                    }}
                    setRequestInfoDialog={setRequestInfoDialog}
                    handleClose={handleClose}
                  />
                </DialogContent>
              </Box>
            </Box>
          </Dialog>
        )}

        <Dialog
          open={requestInfoDialog}
          TransitionComponent={Transition}
          keepMounted
          fullScreen={smallScreen}
          className={smallScreen ? classes.DialoMessageStyle : ""}
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>
            <Box>
              <Grid container>
                <Grid item xs={12}>
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <IconButton
                      onClick={() => {
                        setRequestInfoDialog(false);
                        setopenModalToken(true);
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "20px",
                      textAlign: "center",
                    }}
                  >
                    No puedo validar esta solicitud
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box>
              <Typography>
                Por tu seguridad, si ya no cuentas con tus métodos de validacion
                registrados, comunicate con nosotros.
              </Typography>
              <Box sx={{ width: "100%", display: "flex", pt: 2 }}>
                <Typography sx={{ fontWeight: "bold" }}>
                  Llama al:&nbsp;
                </Typography>

                <Typography
                  sx={{ textDecoration: "underline", color: "#2F6D95" }}
                >
                  01 2302200
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                pr: 2,
                pl: 2,
                pb: 2,
                pt: 2,
              }}
            >
              <Box
                component="div"
                sx={{
                  display: "flex",
                  width: "50%",
                  "& button": { width: "100%" },
                }}
              >
                <Button
                  onClick={() => {
                    setRequestInfoDialog(false);
                    setopenModalToken(true);
                  }}
                  variant="contained"
                  color="primary"
                >
                  Aceptar
                </Button>
              </Box>
            </Box>
          </DialogContent>
        </Dialog>

        <Dialog open={closeProcess}>
          <DialogTitle>
            <Typography sx={{ textAlign: "center" }}>
              ¿Estás seguro que quieres salir del trámite actual?
            </Typography>
          </DialogTitle>
          <DialogActions>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                p: 2,
                borderTop: "1px solid #EDEDED",
              }}
            >
              <Box
                component="div"
                sx={{
                  display: "flex",
                  width: "50%",
                  "& button": { width: "100%" },
                  pr: 1,
                }}
              >
                <Button
                  onClick={() => {
                    history.push("/mi-habitat-digital/afiliado/inicio");
                  }}
                  variant="outlined"
                  color="primary"
                >
                  Salir
                </Button>
              </Box>
              <Box
                component="div"
                sx={{
                  display: "flex",
                  width: "50%",
                  "& button": { width: "100%" },
                  pl: 1,
                }}
              >
                <Button
                  onClick={() => {
                    setCloseProcess(false);
                  }}
                  variant="contained"
                  color="primary"
                >
                  Seguir aquí
                </Button>
              </Box>
            </Box>
          </DialogActions>
        </Dialog>

        {mostrarModalToken && (
          <ModalErrorToken
            Transition={Transition}
            smallScreen={smallScreen}
            mostrarModalToken={mostrarModalToken}
            mensaje={messageErro[0].message}
            classes={classes.DialoMessageStyle}
            cerrarModal={() => {
              setMostrarModalToken(false);
            }}
            guardarCode={() => {
              setMostrarModalToken(false);
              setopenModalToken(true);
            }}
          />
        )}
      </Box>
    </>
  );
};

export default hocWidthWindow(PrimerPaso);
